import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $getRoot } from "lexical";
import { $generateNodesFromDOM, $generateHtmlFromNodes } from "@lexical/html";

export default function UpdatePlugin({ value }) {
  const [editor] = useLexicalComposerContext();

  function update(value) {
    editor.update(() => {
      const htmlString = $generateHtmlFromNodes(editor, null);

      if (value !== htmlString) {
        const root = $getRoot();
        root.clear();

        const parser = new DOMParser();
        const dom = parser.parseFromString(value, "text/html");
        const nodes = $generateNodesFromDOM(editor, dom);

        // Save the current scroll position
        const scrollPosition = window.scrollY;

        nodes.forEach((n) => {
          try {
            $getRoot().append(n);
          } catch (e) {
            const paragraphNode = $createParagraphNode();
            paragraphNode.append(n);
            $getRoot().append(paragraphNode);
          }
        });

        $getRoot().selectEnd();

        setTimeout(() => {
          window.scrollTo(0, scrollPosition);
        }, 500);
      }
    });
  }

  useEffect(() => {
    if (value) {
      update(value);
    }
  }, [value]);

  return null;
}
