import React from "react";
import { Col } from "react-flexbox-grid";
import {
  Switch,
  Status,
  ButtonIcon,
  FormCheckbox,
  FormSelect,
} from "ui-kit-ck-consultant";
import { faEye, faEyeSlash, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalIdentificationPartiesUser from "../../administrative/ModalIdentificationPartiesUser";

import AuthContext from "../../../../context/AuthContext";

export default class SubAttendees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModal: false,
      idx: null,
    };
  }

  static contextType = AuthContext;

  getColor = () => {
    if (this.props.party === "Adverse") {
      return "red bg-red-light";
    } else if (this.props.party === "Lésé") {
      return "blue bg-blue-light";
    } else if (this.props.party === "Aucune") {
      return "green bg-green-light";
    }
  };

  render() {
    return (
      <>
        <Col xs={12} md={3} className="mb-20">
          <div
            className="bg-gray-light p-10 h-100"
            style={{
              borderRadius: "6px",
              overflow: "hidden",
              ...(this.props.data[this.props.idx] === 3
                ? { backgroundColor: "var(--red-light) !important" }
                : {}),
            }}
          >
            <div className="d-flex mb-10">
              <Status
                text={this.props.quality}
                className={`ft-14 ${this.getColor()} mr-10`}
              />
              <>
                {this.props.data[this.props.idx] === 3 ? null : (
                  <ButtonIcon
                    className="ml-auto p-0"
                    small
                    onClick={() => {
                      this.setState({ isModal: true });
                    }}
                  >
                    <FontAwesomeIcon className="blue" icon={faPen} />
                  </ButtonIcon>
                )}
                <ButtonIcon
                  small
                  className="ml-10 p-0 red"
                  info={
                    this.props.data[this.props.idx] === 3
                      ? "Afficher"
                      : "Masquer"
                  }
                  onClick={() => {
                    window.confirmCustom(
                      "Êtes-vous sûr de vouloir masquer cette personne présente ?",
                      (result) => {
                        if (result) {
                          let tmpData = { ...this.props.data };
                          if (this.props.data[this.props.idx] === 3) {
                            tmpData[this.props.idx] = 0;
                          } else {
                            tmpData[this.props.idx] = 3;
                          }

                          this.props.updateDataExpertise(tmpData);
                          if (!this.context.isReport) {
                            this.props.onRemoveLineClick(this.props.idx);
                          }
                        }
                      }
                    );
                  }}
                >
                  <FontAwesomeIcon
                    icon={
                      this.props.data[this.props.idx] === 3 ? faEye : faEyeSlash
                    }
                  />
                </ButtonIcon>
              </>
            </div>
            <p className="m-0 mb-10 break-word">
              <b>
                {this.props.kind} {this.props.name}{" "}
              </b>
            </p>
            <p className="m-0 mb-5 ft-14 break-word">{this.props.society}</p>
            <p className="m-0 mb-5 ft-14 break-word">{this.props.address}</p>
            <p className="m-0 mb-5 ft-14 break-word">{this.props.address2}</p>
            <p className="m-0 mb-5 ft-14 break-word">
              {this.props.zipCode} {this.props.city}
            </p>
            <p className="m-0 mb-5 ft-14 break-word">{this.props.email}</p>
            <p className="m-0 mb-5 ft-14 break-word">{this.props.phone}</p>
            <p className="m-0 mb-5 ft-14 break-word">{this.props.cellphone}</p>
            {this.props.data[this.props.idx] === 3 ? null : (
              <Switch
                className="mb-10 mr-10 w-100"
                title=""
                value1="Absent"
                value2="Présent"
                color1="#f2711c"
                color2="#026fc2"
                idxActive={
                  this.props.data.hasOwnProperty(this.props.idx) &&
                  this.props.data[this.props.idx] !== 2
                    ? this.props.data[this.props.idx]
                    : 0
                }
                onChange={(type) => {
                  let tmpData = this.props.data;
                  tmpData[this.props.idx] = type;
                  if (!this.context.isReport) {
                    if (type) {
                      this.props.onAddLineClick(this.props.idx);
                    } else {
                      this.props.onRemoveLineClick(this.props.idx);
                    }
                  }
                  this.props.updateDataExpertise(tmpData);
                }}
              />
            )}
            {!this.props.data.hasOwnProperty(this.props.idx) ||
            this.props.data[this.props.idx] === 0 ||
            this.props.data[this.props.idx] === 2 ? (
              <FormCheckbox
                className="mt-auto mb-auto"
                text="Excusé"
                checked={this.props.data[this.props.idx] === 2}
                onChange={() => {
                  let tmpData = this.props.data;

                  if (tmpData[this.props.idx] === 2) {
                    tmpData[this.props.idx] = 0;
                  } else {
                    tmpData[this.props.idx] = 2;
                  }

                  this.props.updateDataExpertise(tmpData);
                }}
              />
            ) : null}
            {this.props.data[this.props.idx] === 2 ? (
              <FormSelect
                className="mt-10"
                title="Représenté par"
                value={this.props.data[`${this.props.idx}_by`]}
                options={[
                  {
                    text: "Non représenté",
                    value: "",
                  },
                  ...this.context.partiesOptions.filter((element) => {
                    return true;
                  }),
                ]}
                onChange={({ target }) => {
                  let tmpData = this.props.data;

                  tmpData[`${this.props.idx}_by`] = target.value;

                  this.props.updateDataExpertise(tmpData);
                }}
              />
            ) : null}
          </div>
        </Col>
        <ModalIdentificationPartiesUser
          show={this.state.isModal}
          setData={this.props.setData}
          idx={this.props.idx}
          data={this.props.dataAdministrative}
          onClose={() => this.setState({ isModal: false })}
          isHideDelete={true}
        />
      </>
    );
  }
}
