import React, { Fragment } from "react";
import { Row } from "react-flexbox-grid";
import { ButtonIcon } from "ui-kit-ck-consultant";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIdentificationPartiesUser from "../../administrative/AddIdentificationPartiesUser";
import SubAttendees from "./SubAttendees";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

export default class Attendees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModal: false,
    };

    this.displayName = "Attendees";
  }

  static contextType = AuthContext;

  setDataAdministrative = (data, callback = () => {}) => {
    this.props.updateDataAdministrative(
      "dataIdentificationParties",
      data,
      callback
    );
  };

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("attendees", tmpData);
    } else {
      this.context.updateDataReport("attendees", tmpData);
    }
  };

  getParties = (attendees, party, index) => {
    let data = [];

    attendees.forEach((key) => {
      if (
        party === (this.context.parties[key].party || "Aucune") &&
        ((party !== "Adverse" && party !== "Tiers") ||
          (this.context.parties[key].idParty || 1) === index)
      ) {
        data.push(
          <Fragment key={key}>
            <SubAttendees
              key={key}
              idx={key}
              {...this.context.parties[key]}
              quality={
                this.context.parties[key].quality === "Autre"
                  ? this.context.parties[key].otherQuality
                  : this.context.parties[key].quality
              }
              data={this.props.data}
              dataAdministrative={this.props.dataAdministrative}
              setData={this.setDataAdministrative}
              onAddLineClick={this.props.onAddLineClick}
              onRemoveLineClick={this.props.onRemoveLineClick}
              updateDataExpertise={this.setData}
              isReport={this.context.isReport}
              isExpertise={this.props.isExpertise}
            />
          </Fragment>
        );
      } else {
      }
    });

    return data;
  };

  getData = (attendees) => {
    return (
      <>
        <h2>Lésé</h2>
        <Row>{this.getParties(attendees, "Lésé")}</Row>
        {(() => {
          let items = [];
          const parties = this.getParties(attendees, "Assuré");
          if (parties.length) {
            items.push(<h2>Assuré</h2>);
            items.push(<Row>{parties}</Row>);
          }
          return items;
        })()}
        {(() => {
          let items = [];
          for (let index = 1; index < 6; index++) {
            const parties = this.getParties(attendees, "Tiers", index);
            if (parties.length) {
              items.push(<h2 key={`${index}_title`}>Tiers {index}</h2>);
            }
            items.push(<Row key={`${index}_body`}>{parties}</Row>);
          }
          return items;
        })()}
        {(() => {
          let items = [];
          const parties = this.getParties(attendees, "Réparateur");
          if (parties.length) {
            items.push(<h2>Réparateur</h2>);
            items.push(<Row>{parties}</Row>);
          }
          return items;
        })()}
        {(() => {
          let items = [];
          const parties = this.getParties(attendees, "Dépositaire");
          if (parties.length) {
            items.push(<h2>Dépositaire</h2>);
            items.push(<Row>{parties}</Row>);
          }
          return items;
        })()}
        {(() => {
          let items = [];
          const parties = this.getParties(
            attendees,
            "Centre de contrôle technique"
          );
          if (parties.length) {
            items.push(<h2>Centre de contrôle technique</h2>);
            items.push(<Row>{parties}</Row>);
          }
          return items;
        })()}
        {(() => {
          let items = [];
          for (let index = 1; index < 6; index++) {
            const parties = this.getParties(attendees, "Adverse", index);
            if (parties.length) {
              items.push(<h2 key={`${index}_title`}>Adverse {index}</h2>);
            }
            items.push(<Row key={`${index}_body`}>{parties}</Row>);
          }
          return items;
        })()}

        <h2>Aucune</h2>
        <Row>{this.getParties(attendees, "Aucune")}</Row>
        {this.context.isReport ? null : (
          <AddIdentificationPartiesUser
            data={this.props.dataAdministrative}
            setData={this.setDataAdministrative}
          />
        )}
      </>
    );
  };

  render() {
    let attendees = Object.keys(this.context.parties);
    if (this.context.isReport) {
      return this.getData(attendees);
    }

    return (
      <CustomCard
        title="Personnes présentes"
        name={this.displayName}
        action={
          this.props.data ? (
            <ButtonIcon
              className="black p-0 mr-10"
              small
              onClick={() =>
                window.confirmCustom(
                  "Êtes-vous sûr de vouloir rénitialiser les parties masqués",
                  (result) => {
                    if (result) {
                      let tmpData = this.props.data;

                      tmpData = [];

                      this.setData(tmpData);
                    }
                  }
                )
              }
              info="Rénitialiser les parties masqués"
            >
              <FontAwesomeIcon icon={faRotate} />
            </ButtonIcon>
          ) : null
        }
      >
        {this.getData(attendees)}
      </CustomCard>
    );
  }
}
